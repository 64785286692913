<template>
  <v-container fluid class="option-enum-editor">
    <v-toolbar flat>
      <v-select
        dense
        outlined
        v-model="selectedDimension"
        :items="dimensions"
        return-object
        :loading="loading"
        :disabled="loading"
        placeholder="Select a dimension..."
        item-text="key"
        class="dimension-select"
        data-test-id="dimensionSelect"
      />

      <v-checkbox
        v-if="selectedDimension"
        v-model="isInherit"
        data-test-id="setArrayInheritBtn"
        label="Set array inherit"
      />

      <v-btn
        v-if="restoreable"
        icon
        color="orange"
        :title="restoreTitle"
        @click="restore()"
        class="restore-btn"
      >
        <v-icon>mdi-restore</v-icon>
      </v-btn>
      <v-spacer />
      <v-btn
        v-if="selectedDimension"
        color="primary"
        data-test-id="addOEVBtn"
        @click="createOptionEnumValue"
      >
        Add value
      </v-btn>
    </v-toolbar>
    <v-container fluid class="option-enum-values" v-if="selectedDimension">
      <v-simple-table :data-test-id="'options_table_' + selectedDimension.key">
        <template v-slot:default>
          <thead>
            <tr>
              <th v-for="header in headers" :key="header">{{ header }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="items.length > 0">
            <tr
              v-for="oev in items"
              :key="oev.code"
              :data-test-id="'option_enum_value_' + oev.code"
            >
              <td class="text-body-1">{{ oev.code }}</td>
              <td>
                <input
                  class="v-input value-input"
                  type="text"
                  :value="oev.adminLabel"
                  :ref="'label_' + oev.code"
                  :data-test-id="'label_input_' + oev.code"
                  @input="handleInput(oev, 'adminLabel', $event.target.value)"
                />
              </td>

              <td>
                <input
                  class="v-input value-input"
                  type="number"
                  :value="oev.sortOrder"
                  :data-test-id="'sort_order_input_' + oev.code"
                  @input="
                    handleInput(
                      oev,
                      'sortOrder',
                      $event.target.value
                        ? Number($event.target.value)
                        : undefined
                    )
                  "
                />
              </td>

              <td>
                <v-checkbox
                  dense
                  :input-value="oev.visible"
                  :data-test-id="'visible_input_' + oev.code"
                  @change="handleInput(oev, 'visible', $event)"
                />
              </td>

              <td>
                <input
                  type="text"
                  class="v-input value-input"
                  :value="oev.group1"
                  :data-test-id="'group1_input_' + oev.code"
                  @input="handleInput(oev, 'group1', $event.target.value)"
                />
              </td>

              <td>
                <input
                  type="text"
                  class="v-input value-input"
                  :value="oev.group2"
                  :data-test-id="'group2_input_' + oev.code"
                  @input="handleInput(oev, 'group2', $event.target.value)"
                />
              </td>

              <td class="labels-column">
                <div
                  :ref="'labels_' + oev.code"
                  class="labels-container"
                  tabindex="-1"
                  :data-test-id="'i18n_editor_' + oev.code"
                  @focusout="handleFocusOut('labels_' + oev.code)"
                >
                  <I18nEditor
                    :key="oev.code"
                    :value="oev.labels"
                    :domain="uimodel.domain"
                    :uimodel="{ path: oev.code, data: oev.labels }"
                    @input="handleInput(oev, 'labels', $event)"
                  />
                </div>
              </td>

              <td class="labels-column">
                <div
                  :ref="'short_labels_' + oev.code"
                  class="labels-container"
                  tabindex="-1"
                  :data-test-id="'i18n_editor_short_' + oev.code"
                  @focusout="handleFocusOut('short_labels_' + oev.code)"
                >
                  <I18nEditor
                    :key="oev.code"
                    :value="oev.shortLabels"
                    :domain="uimodel.domain"
                    :uimodel="{ path: oev.code, data: oev.shortLabels }"
                    @input="handleInput(oev, 'shortLabels', $event)"
                  />
                </div>
              </td>

              <td>
                <v-btn
                  v-if="isRemoveable(oev.code)"
                  icon
                  color="red"
                  :data-test-id="'delete_btn_' + oev.code"
                  @click="removeOptionEnumValue(oev)"
                  @input="update"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr class="v-data-table__empty-wrapper">
              <td :colspan="headers.length">No options to display</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
  </v-container>
</template>

<script>
import schemaEditorMixin from "mixins/schema-editor-mixin";
import I18nEditor from "components/configuration/data/editors/specific/I18nEditor";

export default {
  mixins: [schemaEditorMixin],

  components: {
    I18nEditor,
  },

  inject: {
    getPropertyData: {
      default() {
        console.warn("Parent does not provide getPropertyData function");
        return () => {
          return null;
        };
      },
    },

    setTranslation: {
      default() {
        console.warn("Parent does not provide setTranslation function");
      },
    },

    getProductType: {
      default() {
        console.warn("Parent does not provide getProductType function");
        return () => {
          return null;
        };
      },
    },

    addTranslationKey: {
      default() {
        console.warn("Parent does not provide addTranslationKey function");
      },
    },
  },

  props: {
    value: {
      type: Object,
      required: false,
    },

    uimodel: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      schema: this.value ?? this.uimodel.schema,
      selectedDimension: null,
      dimensions: [],
      parentDimensions: [],
      isInherit: false,
      optionEnumValues: {},
      inheritedOEVs: {},
      savedSchema: {},
      loading: false,
      rebuildItems: 0,
      timeout: 0,

      savedLabels: {},
      savedShortLabels: {},
    };
  },

  async mounted() {
    await this.loadDimensions();
  },

  watch: {
    "uimodel.schema": async function (schema) {
      this.schema = schema;
      await this.loadDimensions();
      if (this.selectedDimension)
        this.isInherit = this.optionEnumValues[this.selectedDimension.key].some(
          (oev) => oev.command === "setArrayInherit"
        );
      this.update();
    },

    value(value) {
      this.$set(this.uimodel, "schema", value);
    },

    selectedDimension(dimension) {
      this.isInherit = this.optionEnumValues[dimension.key].some(
        (oev) => oev.command === "setArrayInherit"
      );
    },

    isInherit(isInherit) {
      if (!this.selectedDimension) return;
      this.$set(
        this.optionEnumValues,
        this.selectedDimension.key,
        this.buildOptionEnumValues(this.selectedDimension, isInherit)
      );
      this.update();
    },
  },

  methods: {
    async loadDimensions() {
      try {
        //load all dimension of this domain and the parent domain to get all dimensions and the labels of their options
        //the parent domains labels will be used, when inheritance is activated
        this.loading = true;
        const sourceType = "dimension";
        const keySpace = "productType";
        const dimensions = await this.getPropertyData({
          sourceType,
          keySpace,
          productType: this.productType,
        });

        this.dimensions = dimensions ?? [];

        const domain = this.uimodel.domain ?? this.selectedDomain;
        const parentDomainId = this.$store.state.domains.find(
          ({ id }) => id === domain
        )?.parentId;
        if (parentDomainId) {
          const parentDimensions = await this.getPropertyData({
            sourceType,
            domainId: parentDomainId,
            keySpace,
            productType: this.productType,
          });

          this.parentDimensions = parentDimensions ?? [];
        }

        //build the option enum values for each existing dimension
        this.dimensions.forEach(
          (dimension) =>
            (this.optionEnumValues[dimension.key] =
              this.buildOptionEnumValues(dimension))
        );
      } catch (e) {
        this.$store.commit(
          "SET_ERROR",
          "Error when loading dimensions! Cause: " + e
        );
      } finally {
        this.loading = false;
      }
    },

    buildOptionEnumValues(dimension, isInherit) {
      const key = dimension.key;

      //build the optionEnumValues for the given dimension by combining the OEVs of the given schema,
      //the ones loaded from the dimension endpoint and if inheritance is activated, also the ones from the inherited schemas

      //set the saved data from which the schema can be restored
      this.$set(
        this.savedSchema,
        key,
        this.$cloneObject(this.uimodel?.savedSchema?.properties?.[key])
      );

      //1. get the currently set option enum values on the schema
      let optionEnumValues =
        this.$cloneObject(this.schema?.properties?.[key]?.optionEnumValues) ??
        [];

      //if the "isInherit" variable is not explicitly set and the option enum values have a item with command "setArrayInherit" defined,
      //set isInherit to true
      const hasArrayInherit = optionEnumValues.some(
        ({ command }) => command === "setArrayInherit"
      );
      if (isInherit === undefined && hasArrayInherit) {
        isInherit = true;
      }

      //2. Mixin the option enum values of the inherited schema
      const setArrayInheritIdx = optionEnumValues.findIndex(
        (oev) => oev.command === "setArrayInherit"
      );
      if (isInherit) {
        //Add setArrayInherit command if not already set
        if (setArrayInheritIdx < 0) {
          optionEnumValues.unshift({
            command: "setArrayInherit",
            visible: true,
          });
        }
        //get the inherited option enum values from the domains
        for (const { schema, domain } of this.uimodel.domainSchemas) {
          if (domain === this.selectedDomain) continue;
          const inheritedOptionEnumValues =
            schema?.properties?.[key]?.optionEnumValues;
          const inheritedOEVs = this.$cloneObject(inheritedOptionEnumValues);
          this.mixinInheritedOEVs(optionEnumValues, dimension, inheritedOEVs);
        }
      } else {
        //Remove setArrayInherit command
        if (setArrayInheritIdx >= 0) {
          optionEnumValues.splice(setArrayInheritIdx, 1);
        }
      }

      //4. Add translation labels
      this.$set(this.savedLabels, dimension.key, {});
      this.$set(this.savedShortLabels, dimension.key, {});
      return optionEnumValues
        .filter(({ code, command }) => {
          const hasCode = code !== undefined && code !== null;
          const hasCommand = command !== undefined && command !== null;
          return hasCode || hasCommand;
        })
        .map((oev) => {
          if (
            !dimension.optionEnumValues ||
            oev.command === "setArrayInherit"
          ) {
            return oev;
          }

          let labels;
          let shortLabels;
          const dimensionOev = dimension.optionEnumValues.find(
            (doev) => doev.code === oev.code
          );
          if (dimensionOev) {
            labels = dimensionOev.labels;
            shortLabels = dimensionOev.shortLabels;
          } else if (isInherit && this.parentDimensions.length > 0) {
            const parentDimension = this.parentDimensions.find(
              (parentDimension) => parentDimension.key === key
            );
            //if inheritance is enabled, get the dimension labels of the parent domain OEVs
            const parentDimensionOev = parentDimension?.optionEnumValues?.find(
              (poev) => poev.code === oev.code
            );
            if (parentDimensionOev) {
              labels = Object.assign({}, oev.labels, parentDimensionOev.labels);
              shortLabels = Object.assign(
                {},
                oev.shortLabels ?? {},
                parentDimensionOev.shortLabels ?? {}
              );
            }
          }

          this.$set(oev, "labels", labels ?? {});
          if (labels) {
            let savedLabels = this.savedLabels[dimension.key];
            this.$set(savedLabels, oev.code, this.$cloneObject(labels));
          }

          this.$set(oev, "shortLabels", shortLabels ?? {});
          if (shortLabels) {
            let savedLabels = this.savedShortLabels[dimension.key];
            this.$set(savedLabels, oev.code, this.$cloneObject(shortLabels));
          }

          //Notify the parent component of the translation keys
          this.addTranslationKey(oev.i18nLabel);
          this.addTranslationKey(oev.i18nShortLabel);
          return oev;
        });
    },

    mixinInheritedOEVs(optionEnumValues, dimension, mixin) {
      //add the OEVs of the mixin array to the given optionEnumValues
      if (!mixin || !Array.isArray(mixin)) return;

      //get the OEVs which are currently set in the schema
      const schemaOEVs = this.$cloneObject(
        this.schema?.properties?.[dimension.key]?.optionEnumValues
      );

      mixin.forEach((moev) => {
        if (moev.command === "setArrayInherit") return;
        const idx = optionEnumValues.findIndex((oev) => oev.code === moev.code);
        const oev = optionEnumValues[idx];
        if (this.$isEqual(oev, moev)) return;

        //add to inherited if the mixin OEV is not equal to the existing OEV
        if (!this.inheritedOEVs[dimension.key]) {
          this.$set(this.inheritedOEVs, dimension.key, {});
        }

        //check if there is already an inherited OEV with the same code. If not, create a new one
        let inheritedOEV = this.inheritedOEVs[dimension.key]?.[moev.code] ?? {
          code: moev.code,
          command: moev.command,
          i18nLabel: moev.i18nLabel,
          i18nShortLabel: moev.i18nShortLabel,
        };
        const schemaOEV = schemaOEVs?.find((soev) => soev.code === moev.code);

        if (schemaOEV) {
          //if an option enum value with the same code does already exist in the schema
          //only save the difference as inherited OEV
          Object.keys(moev).forEach((key) => {
            if (key === "labels" || key === "shortLabels") return;
            const schemaVal = schemaOEV[key];
            if (!schemaVal) {
              const value = inheritedOEV?.[key] ?? this.$cloneObject(moev[key]);
              this.$set(inheritedOEV, key, value);
            }
          });
        } else {
          inheritedOEV = this.$cloneObject(moev);
        }

        //remove labels property which might be added by merged data from the dimension endpoint
        this.$delete(inheritedOEV, "labels");
        this.$delete(inheritedOEV, "shortLabels");
        const inheritedOEVs = this.inheritedOEVs[dimension.key];
        this.$set(inheritedOEVs, moev.code, this.$cloneObject(inheritedOEV));

        //if an entry already exists, add the properties of the inherited OEV to the entry
        const clone = this.$cloneObject(inheritedOEV);
        if (oev) {
          //only add non existing properties to the optionEnumValue
          const merged = {
            ...clone,
            ...oev,
          };
          this.$set(optionEnumValues, idx, merged);
          return;
        }
        optionEnumValues.push(clone);
      });
    },

    async createOptionEnumValue() {
      const dimension = this.selectedDimension;
      const codePattern = dimension?.codePattern;
      const optionEnumValues = this.optionEnumValues?.[dimension.key] ?? [];
      //Add a new option to the option enum values
      const code = await this.$prompt("Code of new option enum value", {
        rules: [
          (val) => !!val || "Code is required",
          (val) => {
            const exists = optionEnumValues.some((oev) => oev.code === val);
            //check if there is already an option with the same code
            return (
              !exists ||
              "Option enum value with code " + val + " does already exist"
            );
          },
          (val) => {
            if (!codePattern) return true;
            const regex = new RegExp(codePattern);
            return regex.test(val) || "Code must match pattern " + codePattern;
          },
        ],
      });
      if (!code) return;

      const productType = this.productType.toLowerCase();
      const i18n = productType + "." + dimension.key + "." + code;
      const i18nLabel = i18n + ".label";
      const i18nShortLabel = i18n + ".shortLabel";
      optionEnumValues.push({
        code,
        i18nLabel,
        i18nShortLabel,
        command: "defineOption",
        labels: {},
        shortLabels: {},
      });
      this.update();
      this.$nextTick(() => {
        //focus the label input of the newly created oev
        const input = this.$refs?.["label_" + code]?.[0];
        if (input) input.focus();
      });
    },

    removeOptionEnumValue(optionEnumValue) {
      //remove an existing option enum value from the schema
      const code = optionEnumValue.code;
      const dimension = this.selectedDimension.key;
      const optionEnumValues =
        this.schema?.properties?.[dimension]?.optionEnumValues;
      const idx = optionEnumValues.findIndex((oev) => oev.code === code);
      this.$delete(optionEnumValues, idx);

      const oevs = this.buildOptionEnumValues(this.selectedDimension);
      this.$set(this.optionEnumValues, dimension, oevs);
      this.update();
    },

    handleFocusOut(id) {
      const container = this.$refs[id][0];
      //if the translation containers is about to be hidden,
      //scroll to top to only show the first translation option
      if (getComputedStyle(container).overflow !== "hidden") return;
      container.scrollTop = 0;
    },

    handleInput(obj, key, value) {
      if (value !== undefined && value !== null) this.$set(obj, key, value);
      else this.$delete(obj, key);

      window.clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        this.update();
      }, 300);
    },

    update() {
      //update the current schema by filtering the displayed option enum values
      //and emit the input event
      const dimension = this.selectedDimension.key;
      if (!this.schema?.properties) this.$set(this.schema, "properties", {});
      if (!this.schema.properties[dimension])
        this.$set(this.schema.properties, dimension, { type: "optionEnum" });
      if (!this.schema.properties[dimension].optionEnumValues)
        this.schema.properties[dimension].optionEnumValues = [];

      const optionEnumValues = this.getFilteredOptionEnumValues(dimension);

      //if no option enum values are present, remove the whole dimension from the schema
      if (optionEnumValues.length > 0) {
        const dimensionsSchema = this.schema.properties[dimension];
        this.$set(dimensionsSchema, "optionEnumValues", optionEnumValues);
        const inherit = this.savedSchema?.[dimension]?.inherit ?? true;
        this.$set(dimensionsSchema, "inherit", inherit);
      } else {
        this.$delete(this.schema.properties, dimension);
      }
      this.$emit("input", this.schema);
      this.rebuildItems++;
    },

    getFilteredOptionEnumValues(dimension) {
      //filter the displayed option enum values and return only the changes which should
      //be stored in the schema

      //clone the option enum values to prevent the deleting of labels in the original object
      const optionEnumValues = this.$cloneObject(
        this.optionEnumValues[dimension]
      );
      return optionEnumValues
        .filter((oev) => {
          if (oev.command === "setArrayInherit") return true;

          //check if the labels changed. If yes, add the changed translations in the update queue and remove labels from OEV object
          const savedLabels = this.savedLabels?.[dimension]?.[oev.code];
          if (oev.labels) {
            let labels = this.$cloneObject(oev.labels);
            //Remove all labels which are equal to the saved ones
            if (savedLabels) {
              Object.keys(savedLabels).forEach((locale) => {
                const savedLabel = savedLabels[locale];
                if (labels[locale] === savedLabel) delete labels[locale];
              });
            }
            this.setTranslation(oev.i18nLabel, labels);
          }

          const savedShortLabels =
            this.savedShortLabels?.[dimension]?.[oev.code];
          if (oev.shortLabels) {
            let shortLabels = this.$cloneObject(oev.shortLabels);
            //Remove all shortLabels which are equal to the saved ones
            if (savedShortLabels) {
              Object.keys(savedShortLabels).forEach((locale) => {
                const savedShortLabel = savedShortLabels[locale];
                if (shortLabels[locale] === savedShortLabel) {
                  delete shortLabels[locale];
                }
              });
            }
            this.setTranslation(oev.i18nShortLabel, shortLabels);
          }

          delete oev.labels;
          delete oev.shortLabels;

          //filter out all OEVs who are not inherited or were changed
          let inheritedOev = this.$cloneObject(
            this.inheritedOEVs?.[dimension]?.[oev.code]
          );
          if (!inheritedOev) return true;
          this.$delete(inheritedOev, "labels");
          this.$delete(inheritedOev, "shortLabels");
          //check if the OEV has more schema properties than code, command and i18n
          //if not, the option enum value has not been changed, so do not add it to the schema
          let testOEV = this.$cloneObject(oev);
          delete testOEV.code;
          delete testOEV.command;
          delete testOEV.i18n;
          if (Object.keys(testOEV).length === 0) return false;
          const equals = this.$isEqual(oev, inheritedOev);
          return !equals;
        })
        .map((oev) => {
          //add only the changed option enum values to the schema
          if (!this.inheritedOEVs[dimension]?.[oev.code]) return oev;

          const inheritedOev = this.$cloneObject(
            this.inheritedOEVs[dimension]?.[oev.code]
          );
          const schemaOev = this.$cloneObject(
            this.schema?.properties?.[dimension]?.optionEnumValues?.find(
              (soev) => soev.code === oev.code
            )
          );

          let result = {
            code: oev.code,
            command: oev.command,
            adminLabel: oev.adminLabel,
            i18nLabel: oev.i18nLabel,
            i18nShortLabel: oev.i18nShortLabel,
          };

          //get the changed values of the option enum values and add them to the schema
          Object.keys(oev).forEach((key) => {
            const origVal = oev[key];
            const inheritVal = inheritedOev[key];
            //check if current value != inherited value and if the property is already in the schema
            //if one of both is valid, add the property to the result
            if (!inheritVal || origVal != inheritVal || schemaOev?.[key]) {
              if (origVal) this.$set(result, key, origVal);
              else this.$delete(result, key);
            }
          });

          return result;
        });
    },

    restore() {
      //Restore the dimensions options to the previously saved state
      const key = this.selectedDimension.key;
      this.$set(this.schema?.properties, key, this.savedSchema[key]);
      this.isInherit = this.schema.properties[key].optionEnumValues.some(
        (oev) => oev.command === "setArrayInherit"
      );
      this.$set(
        this.optionEnumValues,
        key,
        this.buildOptionEnumValues(this.selectedDimension, this.isInherit)
      );
      this.update();
    },

    isRemoveable(code) {
      //If an option is stored in the schema it may be removed
      const key = this.selectedDimension.key;
      const optionEnumValues = this.schema?.properties?.[key]?.optionEnumValues;
      return optionEnumValues?.some((oev) => oev.code === code);
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    headers() {
      return [
        "code",
        "adminLabel",
        "sortOrder",
        "visible",
        "group1",
        "group2",
        "labels",
        "shortLabels",
      ];
    },

    productType() {
      //Get product type from currently selected key
      return this.getProductType();
    },

    items() {
      //display only the OEVs of the selected dimension without the setArrayInherit command
      this.rebuildItems;
      if (!this.selectedDimension) return [];
      if (!this.optionEnumValues[this.selectedDimension.key]) return [];
      const items = this.optionEnumValues[this.selectedDimension.key].filter(
        (oev) => oev.command !== "setArrayInherit"
      );
      return items;
    },

    restoreable() {
      this.rebuildItems;
      if (!this.selectedDimension) return false;
      const dimension = this.selectedDimension.key;
      const savedSchema = this.savedSchema[dimension];
      const schema = this?.schema?.properties?.[dimension];
      return savedSchema && !this.$isEqual(schema, savedSchema);
    },

    restoreTitle() {
      return (
        "Restore to " +
        JSON.stringify(
          this.savedSchema[this.selectedDimension.key]
            ? this.savedSchema[this.selectedDimension.key]
            : "undefined"
        )
      );
    },
  },
};
</script>

<style>
.option-enum-editor {
  height: 100%;
}

.option-enum-editor .dimension-select {
  margin-right: 12px;
  max-width: 300px;
}

.option-enum-editor > .v-toolbar > .v-toolbar__content > .restore-btn.v-btn {
  align-self: flex-start;
  height: 36px;
  width: 36px;
  margin-left: 12px;
}

.option-enum-editor > .option-enum-values {
  padding: 0;
  height: calc(100% - 64px);
  max-height: 600px;
  overflow: scroll;
}

.option-enum-editor > .option-enum-values .v-data-table,
.option-enum-editor > .option-enum-values .v-data-table__wrapper {
  height: 100%;
}

.option-enum-editor
  > .option-enum-values
  > .v-data-table
  > .v-data-table__wrapper {
  overflow: scroll;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td.labels-column {
  position: relative;
  height: 73px;
  min-width: 300px;
}

.labels-column > .labels-container {
  z-index: 20;
  overflow: hidden;
  height: 60px;
}

.labels-column > .labels-container:focus-within {
  height: auto;
  width: 100%;
  max-height: 300px;
  overflow: scroll;
  z-index: 9999;
  border: 1px solid rgb(46, 117, 212);
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
    rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  outline: none;
  background-color: white;
  position: absolute;
  left: 0px;
  top: 0px;
  padding: 0px;
}

.option-enum-editor
  .v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover
  > td.labels-column
  > .labels-container
  > .i18n-editor
  .i18n-table:not(:focus-within)
  tr:first-child {
  background-color: #eeeeee;
}

.option-enum-editor .value-input {
  border: 1px solid grey;
  flex: 1 1 auto;
  line-height: 20px;
  max-width: 100%;
  min-width: 0px;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  appearance: auto;
  padding: 8px 12px;
}
</style>